import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Button from "../Button/Button";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Baner.css";

const Baner = ({ bannerData = [] }) => {
  const carouselRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.increment();
        setIsInitialized(true)
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  if(bannerData.length === 0){
    return <div className="shimmer" style={{width:'100%', marginTop:0}}></div>
  }
  return (
    <div className="baner-container">
      <Carousel
        ref={carouselRef}
        showArrows={true}
        autoPlay={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        interval={3000}
        transitionTime={1000}
        onClickItem={() => {
          setIsInitialized(true);
        }}
      >
        {bannerData.map((banner, index) => (
          <div key={index} className="baner-slide">
            <img
              src={`data:image/jpeg;base64,${banner.imagePath}`}
              alt={banner.title}
              className="baner-image"
            />
            <div className="baner-content">
              <p className="baner-title" style={{color:banner.textColor}}>{banner.title}</p>
              <p className="baner-subtitle" style={{color:banner.textColor}}>{banner.subtitle}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Baner;
import React, { useContext, useEffect, useState } from "react";
import Baner from "../../../Components/Baner/Baner";
import LatestArrivals from "../../../Components/LatestArrivals/LatestArrivals";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "../../../Components/Layout/Layout";
import { ProductContext } from "../../../contexts/ProductContext";
import './B2BHomePage.css';

const B2BHomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { searchTerm } = useContext(ProductContext);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Filter products to include only those with productType as "B2B" and that are marked as latest
        const filteredProducts = response.data.filter(
          (item) => item.productType.includes("B2B") && item.isLatest
        );
        setProducts(filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [baseUrl, token]);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        setBannerData(response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();
  }, [baseUrl]);

  useEffect(() => {
    fetch("https://techitprima.onrender.com/api/order")
      .then((res) => res.json())
      .then((data) => {
        if (data.mode) {
          setBannerData([]);
        }
      })
      .catch((err) => console.error("Error fetching order:", err));
  }, []);

  // Filter products based on the search term
  useEffect(() => {
    if (searchTerm) {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [searchTerm, products]);

  return (
    <Layout
      title="B2B | PrimaGifting"
      description="Welcome to the home page."
      favicon="/assets/navLogo.png"
    >
      {loading ? (
        <>
          <div className="shimmer"></div>
          <div className="shimmer"></div>
        </>
      ) : (
        <>
          <Baner bannerData={bannerData} />
          <LatestArrivals products={filteredProducts} />
          <ProductCategories categories={categories} />
        </>
      )}
      <GetInTouch />
    </Layout>
  );
};

export default B2BHomePage;

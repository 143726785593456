import React from "react";
import "../LatestArrivalsB2C/LatestArrivalsB2C.css";
import ProductCatalogueCardB2C from "../ProductCatalogueCardB2C/ProductCatalogueCardB2C";
import ProductCatalogueCardB2E from "../ProductCatalogueCardB2E/ProductCatalogueCardB2E";

const LatestArrivalsB2E = ({ color, products }) => {
  const theme = color != "blue" ? "#EBA9D7" : "#73A9FA";
  return (
    <>
      <div className="latest-arrival-b2c-container">
        <div className="latest-arrival-b2c-title">Latest Arrivals</div>
        <div className="latest-arrival-b2c-card-container">
          {products.map((product) => (
            <ProductCatalogueCardB2E
              key={product._id}
              product={product}
              color={theme}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default LatestArrivalsB2E;

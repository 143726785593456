import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCatalogueCardB2E from '../../../Components/ProductCatalogueCardB2E/ProductCatalogueCardB2E';
import ProductCatalogueCardB2C from '../../../Components/ProductCatalogueCardB2C/ProductCatalogueCardB2C';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import './ByPrice.css';

function ByPrice() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [company, setCompany] = useState("");
  const [isB2B, setIsB2B] = useState(false);
  const [priceRanges, setPriceRanges] = useState({
    range1: false,
    range2: false,
    range3: false,
    range4: false
  });
  const [maxPrice, setMaxPrice] = useState(Infinity);
  const navigate = useNavigate();

  useEffect(() => {
    const companyToken = localStorage.getItem("companyToken");
    const userToken = localStorage.getItem("token");

    if (companyToken) {
      const decodedToken = jwtDecode(companyToken);
      setCompany(decodedToken.id);
      setIsB2B(true);
    } else if (userToken) {
      setIsB2B(false);
    }
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem(isB2B ? "companyToken" : "token");
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const filteredProducts = isB2B
          ? response.data.filter((product) => 
              product.priceByCompany?.some((entry) => entry.companyId === company)
            )
          : response.data.filter((product) =>
              product.productType.includes("B2C")
            );

        setProducts(filteredProducts);
        setFilteredProducts(filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to load products. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [isB2B, company]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = [...products];

      filtered = filtered.filter((product) => {
        const price = isB2B
          ? product.priceByCompany.find((entry) => entry.companyId === company)?.price || 0
          : product.standardPrice || 0;

        return (
          (
            (priceRanges.range1 && price < 500) ||
            (priceRanges.range2 && price >= 500 && price <= 1000) ||
            (priceRanges.range3 && price > 1000 && price <= 1500) ||
            (priceRanges.range4 && price > 1500) ||
            (!priceRanges.range1 && !priceRanges.range2 && !priceRanges.range3 && !priceRanges.range4)
          ) &&
          price <= maxPrice
        );
      });

      filtered.sort((a, b) => {
        const priceA = isB2B
          ? a.priceByCompany.find((entry) => entry.companyId === company)?.price || 0
          : a.standardPrice || 0;
        const priceB = isB2B
          ? b.priceByCompany.find((entry) => entry.companyId === company)?.price || 0
          : b.standardPrice || 0;
        
        return sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
      });

      setFilteredProducts(filtered);
    };

    applyFilters();
  }, [sortOrder, company, priceRanges, maxPrice, products, isB2B]);

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handlePriceRangeChange = (range) => {
    setPriceRanges(prevRanges => ({
      ...prevRanges,
      [range]: !prevRanges[range]
    }));
  };

  const handleSliderChange = (event) => {
    setMaxPrice(parseInt(event.target.value, 10));
  };

  return (
    <div>
      <div className="sort-container">
        <label htmlFor="sortOrder">Sort by Price: </label>
        <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
          <option value="asc">Low to High</option>
          <option value="desc">High to Low</option>
        </select>
      </div>

      <div className="flex-container">
        <div>
          <div className="price-filter-container">
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range1}
                onChange={() => handlePriceRangeChange('range1')}
              />
              &lt; 500
            </label>
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range2}
                onChange={() => handlePriceRangeChange('range2')}
              />
              500 - 1000
            </label>
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range3}
                onChange={() => handlePriceRangeChange('range3')}
              />
              1000 - 1500
            </label>
            <label>
              <input
                type="checkbox"
                checked={priceRanges.range4}
                onChange={() => handlePriceRangeChange('range4')}
              />
              1500 +
            </label>
          </div>

          <div className="slider-container">
            <label htmlFor="priceSlider">Max Price: {maxPrice}</label>
            <input
              id="priceSlider"
              type="range"
              min="500"
              max="10000"
              step="100"
              value={maxPrice}
              onChange={handleSliderChange}
            />
          </div>
        </div>

        <div className="latest-arrival-b2c-container">
          <div className="latest-arrival-b2c-titles">Latest Arrivals</div>
          {loading ? (
            <div className="shimmerr"></div>
          ) : error ? (
            <div className="error-message">{error}</div>
          ) : filteredProducts.length === 0 ? (
            <div className="no-products-message">No products found in this price range.</div>
          ) : (
            <div className="latest-arrival-b2c-card-container">
              {filteredProducts.map((product) =>
                isB2B ? (
                  <ProductCatalogueCardB2E
                    key={product._id}
                    product={product}
                    color="#429bf5"
                    company={company}
                  />
                ) : (
                  <ProductCatalogueCardB2C
                    key={product._id}
                    product={product}
                    color="#429bf5"
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ByPrice;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import ProductCatalogueCardB2E from '../../../Components/ProductCatalogueCardB2E/ProductCatalogueCardB2E';
// import { useNavigate } from "react-router-dom";
// import { jwtDecode } from "jwt-decode";
// import './ByPrice.css';

// function ByPrice() {
//   const token = localStorage.getItem("token");
//   const baseUrl = process.env.REACT_APP_BASE_URL;
//   const [products, setProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [sortOrder, setSortOrder] = useState('asc');
//   const [company, setCompany] = useState("");
//   const [priceRanges, setPriceRanges] = useState({
//     range1: false,
//     range2: false,
//     range3: false,
//     range4: false
//   });
//   const [maxPrice, setMaxPrice] = useState(2000);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem("companyToken");
//     if (token) {
//       const decodedToken = jwtDecode(token);
//       setCompany(decodedToken.id);
//       if (decodedToken.role !== "company") {
//         navigate("/b2e-login");
//       }
//     } else {
//       navigate("/b2e-login");
//     }
//   }, []);

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         setLoading(true);
//         const response = await axios.get(`${baseUrl}/api/product`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         const filteredProducts = response.data.filter((product) => 
//           product.priceByCompany?.some((entry) => entry.companyId === company)
//         );
//         setProducts(filteredProducts);
//         setFilteredProducts(filteredProducts);
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProducts();
//   }, [company]);

//   useEffect(() => {
//     const applyFilters = () => {
//       let filtered = [...products];

//       // Apply price range filters with slider and checkbox constraints
//       filtered = filtered.filter((product) => {
//         const price = product.priceByCompany.find((entry) => entry.companyId === company)?.price || 0;

//         return (
//           (
//             (priceRanges.range1 && price < 500) ||
//             (priceRanges.range2 && price >= 500 && price <= 1000) ||
//             (priceRanges.range3 && price > 1000 && price <= 1500) ||
//             (priceRanges.range4 && price > 1500) ||
//             (!priceRanges.range1 && !priceRanges.range2 && !priceRanges.range3 && !priceRanges.range4)
//           ) &&
//           price <= maxPrice
//         );
//       });

//       // Apply sorting
//       filtered = filtered.sort((a, b) => {
//         const priceA = a.priceByCompany.find((entry) => entry.companyId === company)?.price || 0;
//         const priceB = b.priceByCompany.find((entry) => entry.companyId === company)?.price || 0;
//         return sortOrder === 'asc' ? priceA - priceB : priceB - priceA;
//       });

//       setFilteredProducts(filtered);
//     };

//     applyFilters();
//   }, [sortOrder, company, priceRanges, maxPrice, products]);

//   const handleSortChange = (event) => {
//     setSortOrder(event.target.value);
//   };

//   const handlePriceRangeChange = (range) => {
//     setPriceRanges(prevRanges => ({
//       ...prevRanges,
//       [range]: !prevRanges[range]
//     }));
//   };

//   const handleSliderChange = (event) => {
//     setMaxPrice(parseInt(event.target.value, 10));
//   };

//   return (
//     <div>
//       <div className="sort-container">
//         <label htmlFor="sortOrder">Sort by Price: </label>
//         <select id="sortOrder" value={sortOrder} onChange={handleSortChange}>
//           <option value="asc">Low to High</option>
//           <option value="desc">High to Low</option>
//         </select>
//       </div>

//       <div className="flex-container">
//       <div>
//       <div className="price-filter-container">
//         <label>
//           <input
//             type="checkbox"
//             checked={priceRanges.range1}
//             onChange={() => handlePriceRangeChange('range1')}
//           />
//           &lt; 500
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             checked={priceRanges.range2}
//             onChange={() => handlePriceRangeChange('range2')}
//           />
//           500 - 1000
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             checked={priceRanges.range3}
//             onChange={() => handlePriceRangeChange('range3')}
//           />
//           1000 - 1500
//         </label>
//         <label>
//           <input
//             type="checkbox"
//             checked={priceRanges.range4}
//             onChange={() => handlePriceRangeChange('range4')}
//           />
//           1500 +
//         </label>
//       </div>

//       <div className="slider-container">
//         <label htmlFor="priceSlider">Max Price: {maxPrice}</label>
//         <input
//           id="priceSlider"
//           type="range"
//           min="500"
//           max="2000"
//           step="100"
//           value={maxPrice}
//           onChange={handleSliderChange}
//         />
//       </div>
//       </div>

//       <div className="latest-arrival-b2c-container">
//         <div className="latest-arrival-b2c-titles">Latest Arrivals</div>
//         <div className="latest-arrival-b2c-card-container">
//           {filteredProducts.map((product) => (
//             <ProductCatalogueCardB2E
//               key={product._id}
//               product={product}
//               color="blue"
//               company={company}
//             />
//           ))}
//         </div>
//       </div>
//       </div>
//     </div>
//   );
// }

// export default ByPrice;

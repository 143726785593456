import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AdminEditProduct.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";

const AdminEditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [productData, setProductData] = useState({
    name: "",
    shortDis: "",
    category: "",
    stock: "",
    brand: "",
    standardPrice: "",
    priceByCompany: [],
    additionalInfo: "",
    sku: "",
    images: { featuredImage: "", galleryImages: [] },
    productType: [],
    isLatest: false,
    companyPrice: "",
  });

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [priceByCompany, setPriceByCompany] = useState([]);
  const [mergedCompanies, setMergedCompanies] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/product/${id}`
        );
        setProductData(response.data);

        setPriceByCompany(response.data.priceByCompany); 
        setImagePreviews([
          response.data.images.featuredImage,
          ...response.data.images.galleryImages,
        ]);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    const fetchCategories = async () => {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_BASE_URL;

      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/brand`
        );
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    const fetchCompanies = async () => {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_BASE_URL;

      try {
        const response = await axios.get(`${baseUrl}/api/company`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompanies(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProduct();
    fetchCategories();
    fetchCompanies();
    fetchBrands();
  }, [id]);

      useEffect(() => {
        if (priceByCompany.length && companies.length) {
            const merged = priceByCompany.map(priceEntry => {
                const companyDetails = companies.find(company => company._id === priceEntry.companyId);
                return {
                    ...priceEntry, 
                    name: companyDetails?.name || "Unknown Company",
                    contact: companyDetails?.contact || "No Contact",
                };
            });
            setMergedCompanies(merged);
        }
    }, [priceByCompany, companies]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setProductData((prevData) => {
      const updatedTypes = checked
        ? [...prevData.productType, value]
        : prevData.productType.filter((type) => type !== value);
      return { ...prevData, productType: updatedTypes };
    });
  };

  const handleCompanyPriceChange = (index, field, value) => {
    const updatedPrices = [...productData.priceByCompany];
    updatedPrices[index][field] = value;
    setProductData((prevData) => ({
      ...prevData,
      priceByCompany: updatedPrices,
    }));
  };

  const addCompanyPrice = () => {
    setProductData((prevData) => ({
      ...prevData,
      priceByCompany: [
        ...prevData.priceByCompany,
        { companyId: "", price: "" },
      ],
    }));
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };


  const uploadImages = async () => {
    if (productData.images.galleryImages.length >= 0) {
      try {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_BASE_URL;
  
        const existingFeaturedImage =
          typeof productData.images.featuredImage === "string"
            ? productData.images.featuredImage
            : await convertToBase64(productData.images.featuredImage);
  
        const galleryImagesBase64 = await Promise.all(
          productData.images.galleryImages.map((image) =>
            typeof image === "string" ? image : convertToBase64(image)
          )
        );
  
        const data = {
          featuredImage: existingFeaturedImage,
          galleryImages: galleryImagesBase64,
        };
  
        const response = await axios.put(
          `${baseUrl}/api/product-image/${productData.images._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        if (response.status === 200) {
          return response.data._id;
        }
      } catch (error) {
        if (error.response && error.response.data.message === "No images found") {
          toast.error("No images found. Please add at least one image.");
        } else {
          toast.error("Error uploading image");
          if (error.response && error.response.data.message === "Invalid token") {
            navigate("/login");
          }
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.error("No images selected for upload");
    }
  };
  

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const newPreviews = files.map((file) => URL.createObjectURL(file));

    if (!productData.images.featuredImage) {
      setProductData((prevData) => ({
        ...prevData,
        images: {
          _id: prevData.images._id,
          featuredImage: files[0],
          galleryImages: [...files.slice(1)],
        },
      }));

      setImagePreviews([newPreviews[0], ...newPreviews.slice(1)]);
    } else {
      setProductData((prevData) => ({
        ...prevData,
        images: {
          _id: prevData.images._id,
          ...prevData.images,
          galleryImages: [...prevData.images.galleryImages, ...files],
        },
      }));

      setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    }
  };

  const handleImageRemove = (index) => {
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    const updatedGalleryImages = productData.images.galleryImages.filter(
      (_, i) => i !== index - 1
    );

    if (index === 0) {
      const updatedGalleryImages = productData.images.galleryImages.filter(
        (_, i) => i !== index
      );
      const newFeaturedImage = productData.images.galleryImages[0];

      setImagePreviews(updatedPreviews);
      setProductData((prevData) => ({
        ...prevData,
        images: {
          ...prevData.images,
          featuredImage: newFeaturedImage,
          galleryImages: updatedGalleryImages,
        },
      }));
    } else {
      setImagePreviews(updatedPreviews);
      setProductData((prevData) => ({
        ...prevData,
        images: {
          ...prevData.images,
          galleryImages: updatedGalleryImages,
        },
      }));
    }
  };

  const handleIsLatest = (e) => {
    const { checked } = e.target;
    setProductData((prevData) => {
      return { ...prevData, isLatest: checked };
    });
  };

  const handleSave = async () => {
    setLoading(true);
    
    let imageIds;
    if (
      productData.images.galleryImages.length >= 0 ||
      productData.images.featuredImage
    ) {
      imageIds = await uploadImages();
      if (!imageIds) {
        return; 
      }
    }

  
    const formData = {
      ...productData,
      images: imageIds || productData.images,
    };

    if (!formData.images || formData.images.length === 0) {
      toast.error("No images found");
      return;
    }
  
    try {
      
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/product/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      navigate("/products");
    } catch (error) {
      console.error("Error saving product:", error);
      if (error.response && error.response.data.message === "Invalid token") {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/product/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      navigate("/products");
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const handleQuillChange = (value) => {
    setProductData((prevData) => ({
      ...prevData,
      additionalInfo: value,
    }));
  };

  return (
    <div className="admin-edit-product-container">
      <div className="admin-edit-product-header">
        <p>{productData.name || "Edit Product"}</p>
        <div>
          <button onClick={() => navigate("/products")}>Cancel</button>
          <button onClick={handleSave}>
            {loading ? (
              <div>
                <ClipLoader size={18} color={"#000"} loading={loading} />
              </div>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      <div className="admin-edit-product-summary">
        <div className="admin-edit-product-summary-left">
          <img src={imagePreviews[0]} alt={productData.name} />
        </div>
        <div className="admin-edit-product-summary-right">
          <input
            type="text"
            name="name"
            value={productData.name}
            onChange={handleInputChange}
            placeholder="Product Name"
          />
          <textarea
            name="shortDis"
            value={productData.shortDis}
            onChange={handleInputChange}
            placeholder="Short Description"
          />
        </div>
      </div>
      <div className="admin-edit-product-selection">
        <select
          name="category"
          value={productData.category ? productData.category._id : ""}
          onChange={handleInputChange}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
        <select
          name="brand"
          value={productData.brand ? productData.brand._id : ""}
          onChange={handleInputChange}
        >
          <option value="">Select Brand</option>
          {brands.map((brand) => (
            <option key={brand._id} value={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>

        <input
          type="text"
          name="sku"
          value={productData.sku}
          onChange={handleInputChange}
          placeholder="SKU"
        />
        <label>SKU</label>
        <input
          type="text"
          name="standardPrice"
          value={productData.standardPrice}
          onChange={handleInputChange}
          placeholder="Standard Price"
        />
        <label>PRICE</label>
        <input
          type="Number"
          placeholder="Stock"
          name="stock"
          value={productData.stock}
          onChange={handleInputChange}
        />
        <label>STOCK</label>
      </div>
      <div
        className="admin-edit-product-selection"
        style={{
          marginLeft: 20,
        }}
      >
        <input
          type="Number"
          placeholder="Company Price"
          name="companyPrice"
          value={productData.companyPrice}
          onChange={handleInputChange}
        />
        <label>Company Price</label>
      </div>
      <div className="admin-edit-product-type">
        <label>Select Product Type:</label>
        <div>
          <label>
            <input
              type="checkbox"
              value="B2B"
              checked={productData.productType.includes("B2B")}
              onChange={handleCheckboxChange}
            />
            B2B
          </label>
          <label>
            <input
              type="checkbox"
              value="B2C"
              checked={productData.productType.includes("B2C")}
              onChange={handleCheckboxChange}
            />
            B2C
          </label>
          <label>
            <input
              type="checkbox"
              value="B2E"
              checked={productData.productType.includes("B2E")}
              onChange={handleCheckboxChange}
            />
            B2E
          </label>
          <label>
            <input
              type="checkbox"
              value="isLatest"
              checked={productData.isLatest}
              onChange={handleIsLatest}
            />
            Latest Arrivals
          </label>
        </div>
      </div>
      <div className="admin-edit-product-company-price">
        <div className="admin-edit-product-company-price-upper">
          <button onClick={addCompanyPrice}>Add Company +</button>
        </div>
        {productData.priceByCompany.map((price, index) => (
          <div key={index} className="admin-edit-product-company-price-lower">
            <select
              value={price.companyId}
              onChange={(e) =>
                handleCompanyPriceChange(index, "companyId", e.target.value)
              }
            >
              <option value="">Select Company</option>
                  {mergedCompanies.map((company) => (
                    <option key={company.companyId} value={company.companyId}>
                        {company.name}
                    </option>
                ))}

            </select>
            <input
              type="text"
              value={price.price}
              onChange={(e) =>
                handleCompanyPriceChange(index, "price", e.target.value)
              }
              placeholder="Company Price"
            />
          </div>
        ))}
      </div>
      <div className="admin-edit-product-images">
        <div className="image-upload">
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            accept="image/*"
          />
          <div className="image-previews">
            {imagePreviews.map((preview, index) => (
              <div key={index} className="image-preview">
                <img src={preview} alt={`Preview ${index}`} />
                <button onClick={() => handleImageRemove(index)}>Remove</button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="admin-add-product-discription">
        <ReactQuill
          value={productData.additionalInfo}
          onChange={handleQuillChange}
          placeholder="Description..."
          className="admin-add-product-discription-quil"
        />
      </div>
      <div className="admin-edit-product-buttons">
        <button className="save" onClick={handleSave}>
          {loading ? (
            <div className="">
              <ClipLoader size={18} color={"#fff"} loading={loading} />
            </div>
          ) : (
            "Save"
          )}
        </button>
        <button className="delete" onClick={handleDelete}>
          Delete
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminEditProduct;

import React, { useRef } from 'react';
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import './Testimonials.css';

const Testimonials = ({ color }) => {
  const scrollRef = useRef(null);
  const theme = color !== 'blue' ? '#FFE8F2' : '#D1E4FF';

  const testimonials = [
    {
      text: "I ordered gifts for my team from Primagifting, and the entire process was smooth. The customization options were fantastic, and the quality of the gifts was top-notch. My team loved them! Definitely my go-to for all future gifting needs.",
      author: "Rohit Yadav",
      photo: "/assets/rohit.jpg",
    },
    {
      text: "Primagifting offered a great variety of gifts that fit my budget perfectly. I was able to choose something unique and meaningful for my clients. The entire experience, from ordering to delivery, was hassle-free. Highly recommended!",
      author: "Sneha Mehta",
      photo: "/assets/sneha.jpg",
    },
    {
      text: "I needed personalized gifts for a family event, and Primagifting came through with beautiful options. The gifts were budget-friendly and perfectly aligned with what I had in mind. Everyone loved them, and I couldn’t have been happier with the service.",
      author: "Pankaj Jha",
      photo: "/assets/pankaj.jpg",
    },
  ];

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
  };

  return (
    <div className="testimonial-container" style={{ background: theme, height:'500px', maxWidth:'100vw' }}>
      <p className="testimonial-header" style={{ background: theme, marginBottom:'60px' }}>Testimonials</p>
      <div className="testimonial-cards-container">
        <button onClick={() => scroll(-400)} className="scroll-button left">
          &#8249;
        </button>
        <div className="testimonial-scroll" ref={scrollRef}>
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={index}
              text={testimonial.text}
              author={testimonial.author}
              photo={testimonial.photo}
            />
          ))}
        </div>
        <button onClick={() => scroll(400)} className="scroll-button right">
          &#8250;
        </button>
      </div>
    </div>
  );
};

export default Testimonials;

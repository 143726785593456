import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import "./B2BProductList.css";
import axios from "axios";
import ProductCatalogueCard from "../../../Components/ProductCatalogueCard/ProductCatalogueCard";
import ClipLoader from "react-spinners/ClipLoader";
import { jwtDecode } from "jwt-decode";
import ProductCatalogueCardB2E from "../../../Components/ProductCatalogueCardB2E/ProductCatalogueCardB2E";

const B2EProductList = () => {
  const [products, setProducts] = useState([]); // Currently visible products
  const [allProducts, setAllProducts] = useState([]); // All fetched products
  const [categories, setCategories] = useState([]);
  const [sortOrder, setSortOrder] = useState("latest");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const [companyId, setCompanyId] = useState("");
  const [selectedCategoryName, setSelectedCategoryName] = useState(location.state?.categoryName || "");
  const navigate = useNavigate();

  const token = localStorage.getItem("companyToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setCompanyId(decodedToken.id);
      if (decodedToken.role !== "company") {
        navigate("/b2b-product-list");
      }
    } else {
      navigate("/b2b-product-list");
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/product`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const filteredProducts = response.data.filter(
          (item) =>
            item.priceByCompany.some((entry) => entry.companyId === companyId)
        );

        setAllProducts(filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchProducts();
  }, [companyId, token, baseUrl]);

  useEffect(() => {
    filterProducts(allProducts); // Filter products whenever the selected category changes
  }, [selectedCategoryName, allProducts]);

  const filterProducts = (productsToFilter) => {
    const finalProducts = selectedCategoryName && selectedCategoryName !== ""
      ? productsToFilter.filter(
          (item) => item.category.name === selectedCategoryName
        )
      : productsToFilter;

    setProducts(finalProducts); // Update displayed products
  };

  const selectCat = (catId, catName) => {
    setSelectedCategoryName(catName);
  };

  const handleSortOrderChange = (event) => setSortOrder(event.target.value);

  const sortedProducts = [...products].sort((a, b) =>
    sortOrder === "latest"
      ? new Date(b.createdAt) - new Date(a.createdAt)
      : new Date(a.createdAt) - new Date(b.createdAt)
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="product-list-container">
      <div className="product-list-title">Product Catalogue</div>
      <div className="product-list-showcase">
        <div className="product-list-cat-list-container">
          <h1>Categories</h1>
          {categories.map((cat) => (
            <p key={cat._id} onClick={() => selectCat(cat._id, cat.name)}>
              {cat.name}
            </p>
          ))}
        </div>
        <div className="product-list-cat-container">
          <div className="product-list-selection">
            <select onChange={handleSortOrderChange} value={sortOrder}>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
            </select>
            <p>
              Showing {indexOfFirstProduct + 1} to{" "}
              {Math.min(indexOfLastProduct, products.length)} of{" "}
              {products.length} results
            </p>
          </div>
          <div className="product-list-card-container">
            {loading ? (
              <div className="loader-b2b">
                <ClipLoader color={"#000"} loading={loading} size={30} />
              </div>
            ) : (
              currentProducts.map((product) => (
                <ProductCatalogueCardB2E key={product._id} product={product} color="skyblue" />
              ))
            )}
          </div>
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(products.length / productsPerPage) },
              (_, index) => (
                <button key={index + 1} onClick={() => paginate(index + 1)}>
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2EProductList;

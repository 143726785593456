import Footer from "../../../Components/Footer/Footer";
import Nav from "../../../Components/Nav/Nav";
import styles from "./TnC.module.css";

const TermsAndConditions = () => {
  const token = localStorage.getItem("companyToken");
  const theme = token ? "blue" : "";

  return (
    <>
      <Nav color={theme} />
      <div className={styles.container}>
        <div className={styles.policyContent}>
          <h1 className={styles.title}>Terms and Conditions</h1>
          <p className={styles.text}>
            These Terms and Conditions ("Terms") govern your use of the Prima
            Gifting website{" "}
            <a href="www.primagifting.com">www.primagifting.com</a>, services,
            and any transactions conducted through the website. By accessing or
            using our website, you agree to these Terms. Please read them
            carefully.
          </p>

          <h2 className={styles.subtitle}>1. Introduction</h2>
          <p className={styles.text}>
            Prima Gifting is an online store based in India, offering a range of
            gifting products. By using our website or purchasing products from
            us, you agree to comply with these Terms.
          </p>
          <p className={styles.text}>
            These Terms apply to all users of the site, including browsers,
            customers, vendors, and merchants. If you do not agree to these
            Terms, please discontinue the use of our services.
          </p>

          <h2 className={styles.subtitle}>2. Eligibility</h2>
          <p className={styles.text}>
            You must be at least 18 years of age to make a purchase on our
            website. By placing an order, you confirm that you are legally
            eligible to enter into binding contracts.
          </p>

          <h2 className={styles.subtitle}>3. Product Information</h2>
          <p className={styles.text}>
            We strive to provide accurate descriptions and images of our
            products. However, slight variations in color, design, or packaging
            may occur due to differences in monitors or manufacturing updates.
          </p>
          <p className={styles.text}>
            All product prices are listed in Indian Rupees (INR) and are
            inclusive of applicable taxes unless stated otherwise.
          </p>

          <h2 className={styles.subtitle}>4. Order Processing</h2>
          <p className={styles.text}>
            <strong>Placing an Order:</strong> Once you place an order on our
            website, you will receive a confirmation email summarizing the
            details. This email confirms that we have received your order, but
            it does not guarantee acceptance or shipment.
          </p>
          <p className={styles.text}>
            <strong>Order Acceptance:</strong> We reserve the right to accept or
            reject any order. If your order is rejected or if we are unable to
            fulfill it due to product unavailability, you will be notified, and
            any payments made will be refunded.
          </p>

          <h2 className={styles.subtitle}>5. Payment Terms</h2>
          <p className={styles.text}>
            <strong>Payment Gateway:</strong> Payments for orders are processed
            through Razorpay, a third-party payment gateway. All payment
            information, such as credit card or bank details, is securely
            processed by Razorpay, and Prima Gifting does not store or handle
            such sensitive data.
          </p>
          <p className={styles.text}>
            <strong>Payment Methods:</strong> We accept major credit cards,
            debit cards, UPI, and net banking via Razorpay. Payment must be made
            in full before your order is dispatched.
          </p>

          <h2 className={styles.subtitle}>6. Shipping and Delivery</h2>
          <p className={styles.text}>
            We offer online delivery services across India. Shipping charges and
            delivery times may vary depending on your location and the product
            selected.
          </p>
          <p className={styles.text}>
            <strong>Delivery Timeframes:</strong> Estimated delivery times will
            be provided at checkout, and while we make every effort to meet
            these timelines, delays due to unforeseen circumstances may occur.
          </p>
          <p className={styles.text}>
            <strong>Delivery Confirmation:</strong> Once your order is
            dispatched, you will receive a shipping confirmation email with
            tracking details. Delivery times may vary based on your location.
          </p>

          <h2 className={styles.subtitle}>7. No Refund Policy</h2>
          <p className={styles.text}>
            <strong>No Refund Once Shipped:</strong> Please note that once the
            product is dispatched or out for delivery, no refunds will be
            issued. We encourage customers to thoroughly review their order
            before confirming the purchase.
          </p>
          <p className={styles.text}>
            <strong>Damaged or Incorrect Items:</strong> If you receive a
            damaged or incorrect item, please notify us within 24 hours of
            delivery. We will review your request and may offer a replacement or
            refund under exceptional circumstances.
          </p>

          <h2 className={styles.subtitle}>8. Cancellations</h2>
          <p className={styles.text}>
            Orders can be canceled within 2 hours of placing them. Beyond this,
            cancellations are not possible as the order enters processing. If
            you need to cancel an order, contact us immediately at{" "}
            <a className={styles.email} href="mailto:contact@primagifting.com">
              contact@primagifting.com
            </a>
            .
          </p>

          <h2 className={styles.subtitle}>9. Return and Replacement Policy</h2>
          <p className={styles.text}>
            <strong>Damaged or Defective Products:</strong> In the rare case
            that you receive a damaged or defective item, please contact us
            within 24 hours of receiving the product. We will guide you through
            the process of returning the item for a replacement.
          </p>
          <p className={styles.text}>
            <strong>Replacement Requests:</strong> To be eligible for a
            replacement, the item must be unused and in its original condition.
            The original packaging and proof of purchase must also be included.
          </p>

          <h2 className={styles.subtitle}>10. User Conduct</h2>
          <p className={styles.text}>When using our website, you agree to:</p>
          <ul className={styles.list}>
            <li className={styles.text}>
              Not engage in any unlawful activities or upload harmful,
              defamatory, or illegal content.
            </li>
            <li className={styles.text}>
              Not interfere with the website's security, performance, or
              functionality.
            </li>
            <li className={styles.text}>
              Provide accurate information during account creation or order
              placement, and promptly update it as necessary.
            </li>
          </ul>

          <h2 className={styles.subtitle}>11. Intellectual Property Rights</h2>
          <p className={styles.text}>
            All content, images, logos, and designs on the Prima Gifting website
            are the intellectual property of Prima Gifting and are protected by
            Indian copyright laws. You may not use, reproduce, or distribute any
            content without our express permission.
          </p>

          <h2 className={styles.subtitle}>12. Limitation of Liability</h2>
          <p className={styles.text}>
            Prima Gifting is not liable for any indirect, incidental, or
            consequential damages arising out of the use or inability to use our
            website or services.
          </p>
          <p className={styles.text}>
            While we strive to ensure the accuracy and reliability of our
            product listings and descriptions, Prima Gifting is not responsible
            for any inaccuracies or typographical errors.
          </p>

          <h2 className={styles.subtitle}>
            13. Governing Law and Jurisdiction
          </h2>
          <p className={styles.text}>
            These Terms and any transactions conducted on the website are
            governed by the laws of India. Any disputes arising from these Terms
            will be subject to the exclusive jurisdiction of the courts in
            Mumbai, Maharashtra.
          </p>

          <h2 className={styles.subtitle}>14. Modification of Terms</h2>
          <p className={styles.text}>
            Prima Gifting reserves the right to modify these Terms at any time.
            We will notify you of any changes by updating the "Effective Date"
            at the top of this document. Continued use of the website following
            any changes signifies your acceptance of the updated Terms.
          </p>

          <h2 className={styles.subtitle}>15. Contact Information</h2>
          <p className={styles.text}>
            For questions or concerns regarding these Terms and Conditions,
            please contact us:
          </p>
          <p className={styles.text}>
            Prima Gifting
            <br />
            Email:{" "}
            <a className={styles.email} href="mailto:contact@primagifting.com">
              contact@primagifting.com
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AdminProducts.css";

const AdminProducts = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_BASE_URL;

      const config = {
        method: "get",
        url: `${baseUrl}/api/product`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios(config);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const addProduct = () => {
    navigate("/add-product");
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleEditProduct = (productId) => {
    navigate(`/edit-product/${productId}`);
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true)
      try {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_BASE_URL;

        const config = {
          method: "delete",
          url: `${baseUrl}/api/product/${productId}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        await axios(config);
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== productId)
        );
        fetchProducts()
      } catch (error) {
        console.error("Error deleting product:", error);
      } finally{
        setLoading(false)
      }
  };

  useEffect(() => {
    setLoading(true)
    fetch("https://techitprima.onrender.com/api/product")
      .then((res) => res.json())
      .then((data) => {
        if (data.mode) {
          setProducts(null);
        }
      })
      .catch((err) => console.error("Error fetching product:", err));
      setLoading(false)
  }, []);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-products-container">
      <div className="admin-product-header">
        <div className="admin-product-header-left">
          <p>All Products</p>
          <button onClick={addProduct}>Add New</button>
        </div>
        <div className="admin-product-header-right">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
          />
          <button className="admin-search-button">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div className="admin-product-table-container">
          {
            loading ? (
              <>
                <div className="shimmer"></div>
              </>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>SKU</th>
                    <th>Stock</th>
                    <th>Price</th>
                    <th>Category</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                    {filteredProducts.map((product) => {
                      const imageUrl = product?.images
                        ? `${process.env.REACT_APP_BASE_URL}/path/to/images/${product.images}`
                        : "/assets/product.svg";

                      return (
                        <tr key={product?._id}>
                          <td>
                            <img
                              src={`${product?.images?.featuredImage}`}
                              alt="Featured"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/assets/product.svg";
                              }}
                            />
                          </td>
                          <td>{product?.name}</td>
                          <td>{product?.sku}</td>
                          <td>{product?.stock}</td>
                          <td>₹{product?.standardPrice}</td>
                          <td>{product?.category?.name}</td>
                          <td>
                            <i
                              className="fa-solid fa-pen"
                              onClick={() => handleEditProduct(product?._id)}
                              style={{ cursor: "pointer", marginRight: "10px" }}
                            ></i>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleDeleteProduct(product?._id)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
              </table>
            )
          }
      </div>
    </div>
  );
};

export default AdminProducts;

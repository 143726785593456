import React from 'react';
import { FaStar } from 'react-icons/fa';
import './TestimonialCard.css';

const TestimonialCard = ({ text, author, photo }) => {
  return (
    <div className="testimonial-card-container">
      <img src={photo} style={{width:'80px', height:'80px'}} alt="Testimonial" className="testimonial-card-img" />
      <p className="testimonial-card-sub-title">{text}</p>
      <p className="testimonial-card-title">{author}</p>
      <div className="testimonial-stars">
        {[...Array(5)].map((_, index) => (
          <FaStar key={index} color="#FFD700" />
        ))}
      </div>
    </div>
  );
};

export default TestimonialCard;

import Footer from "../../../Components/Footer/Footer";
import Nav from "../../../Components/Nav/Nav";
import styles from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  const token = localStorage.getItem("companyToken");
  const theme = token ? "blue" : "";

  return (
    <>
      <Nav color={theme} />
      <div className={styles.container}>
        <div className={styles.policyContent}>
          <h1 className={styles.title}>Privacy Policy</h1>

          <p className={styles.description}>
            At Prima Gifting, accessible from{" "}
            <a href="www.primagifting.com">www.primagifting.com</a>, one of our
            main priorities is the privacy of our visitors and customers. This
            Privacy Policy outlines the types of information that is collected
            and recorded by Prima Gifting and how we use it. This policy is in
            compliance with Indian privacy laws and the Razorpay payment gateway
            requirements.
          </p>

          <h2 className={styles.subtitle}>1. Information We Collect</h2>
          <p className={styles.text}>
            We collect different types of information when you interact with us
            through our website, including but not limited to:
          </p>
          <ul className={styles.list}>
            <li>
              <strong>Personal Identification Information:</strong> This
              includes details such as your name, email address, phone number,
              and delivery address, which are collected when you place an order
              or create an account on our website.
            </li>
            <li>
              <strong>Payment Information:</strong> For secure payment
              processing, we collect necessary payment details through our
              third-party payment gateway, Razorpay. Prima Gifting does not
              store sensitive payment information like credit card or bank
              details on its servers. All payment transactions are processed
              securely by Razorpay.
            </li>
            <li>
              <strong>Order Details:</strong> Information about the products you
              purchase, such as the product name, quantity, price, and delivery
              preferences, is collected to fulfill your order.
            </li>
            <li>
              <strong>Browser and Device Information:</strong> We automatically
              collect technical information like your IP address, browser type,
              and operating system to help improve your browsing experience and
              provide better services.
            </li>
            <li>
              <strong>Cookies and Tracking Technologies:</strong> Like most
              websites, we use cookies and similar tracking technologies to
              enhance user experience, track site traffic, and understand user
              preferences.
            </li>
          </ul>

          <h2 className={styles.subtitle}>2. How We Use Your Information</h2>
          <p className={styles.text}>
            We use the collected information for various purposes, including but
            not limited to:
          </p>
          <ul className={styles.list}>
            <li>
              <strong>Order Fulfillment:</strong> Your personal and payment
              information is used to process your orders and deliver products to
              you.
            </li>
            <li>
              <strong>Customer Support:</strong> To respond to your inquiries,
              complaints, or requests and provide assistance with any issues you
              may face while using our servicesrs.
            </li>
            <li>
              <strong>Marketing and Promotions:</strong> With your consent, we
              may send you promotional emails or offers about new products,
              special deals, or discounts. You can opt-out of these
              communications at any time.
            </li>
            <li>
              <strong>Site Improvement:</strong> The technical data and usage
              patterns collected help us improve our website’s performance,
              troubleshoot issues, and ensure a better overall user experience.
            </li>
            <li>
              <strong>Legal Compliance:</strong> To comply with applicable laws,
              regulations, and requests from government authorities where .
            </li>
          </ul>

          <h2 className={styles.subtitle}>
            3. Information Sharing and Disclosure
          </h2>
          <p className={styles.text}>
            We respect your privacy, and your personal data will only be shared
            in specific circumstances:
          </p>
          <ul className={styles.list}>
            <li>
              <strong>Service Providers:</strong> We share your data with
              third-party service providers such as Razorpay for payment
              processing and courier companies for order delivery. These
              providers have their own privacy policies, and they only receive
              the necessary information to complete their tasks.
            </li>
            <li>
              <strong>Legal Obligations:</strong> We may disclose your
              information if required by law or in response to a legal request,
              such as a court order or government inquiry.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger,
              acquisition, or sale of assets, your data may be transferred to
              the new entity, in which case we will notify you of the change in
              ownership and the privacy implications.
            </li>
          </ul>
          <h2 className={styles.subtitle}>4. Data Retention</h2>
          <p className={styles.text}>
            We retain your personal information for as long as it is necessary
            to fulfill the purposes outlined in this Privacy Policy, or for
            legal and regulatory compliance, whichever is longer. Your payment
            information is securely processed and stored by Razorpay, and we do
            not retain any payment-related details on our servers.
          </p>

          <h2 className={styles.subtitle}>5. Data Security</h2>
          <p className={styles.text}>
            We implement appropriate physical, technical, and organizational
            security measures to safeguard your data from unauthorized access,
            alteration, or disclosure. Our payment gateway provider, Razorpay,
            adheres to strict security protocols, including encryption and
            compliance with PCI DSS (Payment Card Industry Data Security
            Standards).
            <br />
            <br />
            However, please note that no method of transmission over the
            internet or method of electronic storage is 100% secure, and while
            we strive to protect your data, we cannot guarantee absolute
            security.
          </p>

          <h2 className={styles.subtitle}>6. Your Data Protection Rights</h2>
          <p className={styles.text}>
            As a user of Prima Gifting, you have the following rights:
          </p>
          <ul className={styles.list}>
            <li>
              <strong>Right to Access:</strong> You can request access to your
              personal data that we hold.
            </li>
            <li>
              <strong>Right to Rectification:</strong> You can request
              corrections if you believe that any of your personal information
              is inaccurate or incomplete.
            </li>
            <li>
              <strong>Right to Erasure:</strong> Under certain conditions, you
              have the right to request that we erase your personal data from
              our records, excluding data required for legal or operational
              purposes.
            </li>
            <li>
              <strong>Right to Object:</strong> You can object to the processing
              of your personal data for marketing purposes.
            </li>
            <li>
              <strong>Right to Data Portability:</strong> You can request a copy
              of your data in a structured, commonly used, and machine-readable
              format.
            </li>
          </ul>
          <p className={styles.text}>
            To exercise any of these rights, contact us at{" "}
            <a href="mailto:contact@primagifting.com" className={styles.email}>
              contact@primagifting.com
            </a>
            .
          </p>

          <h2 className={styles.subtitle}>
            7. Cookies and Tracking Technologies
          </h2>
          <p className={styles.text}>
            Prima Gifting uses cookies to store user preferences, record session
            information, and to understand website traffic. You can manage
            cookies through your browser settings. However, disabling cookies
            may affect your user experience on our website.
          </p>

          <h2 className={styles.subtitle}>8. Third-Party Links</h2>
          <p className={styles.text}>
            Our website may contain links to other websites not operated by
            Prima Gifting. Please note that we are not responsible for the
            privacy practices or content of such third-party sites. We encourage
            you to review the privacy policies of those websites.
          </p>

          <h2 className={styles.subtitle}>9. Children’s Privacy</h2>
          <p className={styles.text}>
            Our website is not intended for individuals under the age of 18. We
            do not knowingly collect personal information from minors. If we
            discover that a child under the age of 18 has provided us with
            personal data, we will delete it promptly.
          </p>

          <h2 className={styles.subtitle}>
            10. Changes to this Privacy Policy
          </h2>
          <p className={styles.text}>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices, technologies, or legal requirements. Any
            changes will be posted on this page, and the updated version will
            take effect as soon as it is published. We encourage you to review
            this policy periodically to stay informed about how we protect your
            data.
          </p>

          <h2 className={styles.subtitle}>11. Contact Us</h2>
          <p className={styles.text}>
            If you have any questions or concerns about this Privacy Policy or
            how we handle your personal data, please contact us at:
          </p>
          <address className={styles.contact}>
            Prima Gifting
            <br />
            Email:{" "}
            <a href="mailto:contact@primagifting.com" className={styles.email}>
              contact@primagifting.com
            </a>
          </address>
        </div>
      </div>
      <Footer />
    </>
  );
}

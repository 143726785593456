import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";
import "./ProductCatalogueCard.css";
import { useQuote } from "../../contexts/QuoteContext";

const ProductCatalogueCard = ({ color, product }) => {
  const [cartToggle, setCartToggle] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { quote, removeQuote, addQuote } = useQuote();
  const navigate = useNavigate();

  useEffect(() => {
    const isInCart = quote.some((item) => item._id === product?._id);
    setCartToggle(!isInCart);
  }, [product, quote]);

  const handleAddToCart = () => {
    addQuote({ ...product, quantity: 1 });
    setCartToggle(false); // Switch to "Remove from Quote"
  };

  const handleRemoveFromCart = () => {
    removeQuote(product);
    setCartToggle(true); // Switch to "Add to Quote"
  };

  useEffect(() => {
    // Auto-scroll feature for the carousel
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === product?.images?.galleryImages.length - 1
          ? 0
          : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [product?.images?.galleryImages.length]);

  return (
    <div className="cat-card">
      {product?.images?.galleryImages.length > 0 ? (
        <div
          className="carousel"
          onClick={() => {
            navigate(`/b2b-product-page/${product?._id}`);
          }}
        >
          <img
            src={product?.images?.galleryImages[currentImageIndex]}
            className="cat-card-img"
            onClick={() => {
              navigate(`/b2b-product-page/${product?._id}`);
            }}
            alt="Product Image"
          />
        </div>
      ) : (
        <img
          src={product?.images?.featuredImage}
          className="cat-card-img"
          onClick={() => {
            navigate(`/b2b-product-page/${product?._id}`);
          }}
          alt="Featured Product Image"
        />
      )}
      {cartToggle ? (
        <button
          className="cat-button"
          style={{ backgroundColor: `${color}` }}
          onClick={handleAddToCart}
        >
          Add to Quote
        </button>
      ) : (
        <button
          className="cat-button"
          style={{ backgroundColor: `${color}` }}
          onClick={handleRemoveFromCart}
        >
          Remove from Quote
        </button>
      )}
      <div className="cat-title-container">
        <div>
          <p className="cat-pro-sub-title">{product?.name}</p>
          <p className="cat-pro-title">
            {product?.shortDis.length > 30
              ? `${product?.shortDis.substring(0, 30)}...`
              : product?.shortDis}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCatalogueCard;

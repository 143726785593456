import styles from "./RefundnCancellation.module.css";
import Nav from "./../../../Components/Nav/Nav";
import Footer from "../../../Components/Footer/Footer";

const RefundnCancellation = () => {
  const token = localStorage.getItem("companyToken");
  const theme = token ? "blue" : "";

  return (
    <>
      <Nav color={theme} />
      <div className={styles.container}>
        <div className={styles.policyContent}>
          <h1 className={styles.title}>Refund and Cancellation Policy</h1>
          <p className={styles.text}>
            At Prima Gifting, we strive to ensure a positive shopping experience
            for all our customers. Our refund and cancellation policy has been
            carefully designed to provide clarity and transparency, while also
            considering the nature of our business and delivery processes.
          </p>
          <h2 className={styles.subtitle}>1. Order Cancellation Policy</h2>
          <p className={styles.text}>
            <strong>Timeframe for Cancellation:</strong> You may cancel your
            order within 2 hours of placing it. After this window, the order
            will be processed, and cancellations will not be accepted.
          </p>
          <p className={styles.text}>
            <strong>How to Cancel:</strong> To cancel an order, please contact
            us at{" "}
            <a className={styles.email} href="mailto:contact@primagifting.com">
              contact@primagifting.com
            </a>{" "}
            or through our customer service portal. Ensure to provide your order
            ID and details for swift cancellation.
          </p>
          <p className={styles.text}>
            <strong>Cancellation Charges:</strong> Orders canceled within the
            allowed timeframe will not incur any cancellation fees. However,
            once processing begins, we cannot reverse the order.
          </p>

          <h2 className={styles.subtitle}>2. Refund Policy</h2>
          <p className={styles.text}>
            <strong>No Refund Policy:</strong> Please note that once an order
            has been shipped or is out for delivery, no refunds will be
            processed. We strongly advise reviewing your order details carefully
            before confirming the purchase.
          </p>
          <p className={styles.text}>
            <strong>Exceptions for Refunds:</strong> While we have a no-refund
            policy for delivered products, we do consider refunds under the
            following exceptional circumstances:
          </p>
          <ul className={styles.list}>
            <li className={styles.text}>
              Damaged or Defective Products: If you receive a product that is
              damaged, defective, or not as described, please notify us within
              24 hours of delivery by contacting us at{" "}
              <a
                className={styles.email}
                href="mailto:contact@primagifting.com"
              >
                contact@primagifting.com
              </a>
              . We may request images or other proof of the issue.
            </li>
            <li className={styles.text}>
              Incorrect Orders: If you receive a wrong item that differs from
              what was ordered, please contact us immediately for a resolution.
            </li>
          </ul>

          <p className={styles.text}>
            <strong>Refund Process:</strong> Upon verifying the damage or
            defect, we will arrange for a replacement or issue a refund,
            depending on your preference. Refunds will be processed through the
            same payment method used for the purchase.
            <br />
            <br />
            The refund process may take 5-7 business days once approved,
            depending on your payment provider's policies.
          </p>

          <h2 className={styles.subtitle}>
            3. Non-Refundable Products and Services
          </h2>
          <p className={styles.text}>
            <strong>Personalized or Customized Products:</strong> Items that are
            personalized or customized as per your request are non-refundable
            unless found to be defective or damaged upon receipt.
          </p>
          <p className={styles.text}>
            <strong>Non-Returnable Items:</strong> Certain products, such as
            perishable goods, consumables, or limited-time items, may not be
            eligible for returns or refunds. These exclusions will be clearly
            mentioned in the product description.
          </p>

          <h2 className={styles.subtitle}>4. Replacement Policy</h2>
          <p className={styles.text}>
            <strong>Damaged or Defective Products:</strong> If a product arrives
            in a damaged condition or has manufacturing defects, we offer a
            replacement service. Notify us within 24 hours of receiving the
            product, and we will guide you through the replacement process.
          </p>
          <p className={styles.text}>
            <strong>Incorrect Orders:</strong> If you receive an incorrect
            product, we will replace it with the correct item at no extra
            charge. Please reach out to us immediately after the delivery.
          </p>

          <h2 className={styles.subtitle}>
            5. Delivery Timeframe
          </h2>
          <p className={styles.text}>
            <strong>Minimum and Maximum Delivery Time</strong>
            Orders will typically be delivered within a minimum of 3 days and a maximum of 10 days from the date of order confirmation. Delivery times may vary depending on your location, product availability, and other factors, but we make every effort to deliver within this window.
          </p>

          <h2 className={styles.subtitle}>
            6. Shipping for Returns and Replacements
          </h2>
          <p className={styles.text}>
            In cases where a return or replacement is authorized, Prima Gifting
            will arrange for the item to be picked up from your delivery
            address. You will not be required to bear any additional shipping
            costs for returned or replaced items.
          </p>

          <h2 className={styles.subtitle}>7. Contact Information</h2>
          <p className={styles.text}>
            For any concerns or questions related to cancellations, refunds, or
            replacements, please feel free to contact us:
          </p>
          <p className={styles.text}>
            <strong>Prima Gifting</strong>
            <br />
            <a className={styles.email} href="mailto:contact@primagifting.com">
              contact@primagifting.com
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundnCancellation;

import React, { useEffect, useState, useContext } from "react";
import Baner from "../../../Components/Baner/Baner";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import LatestArrivalsB2E from "../../../Components/LatestArrivalsB2E/LatestArrivalsB2E";
import Testimonials from "../../../Components/Testimonials/Testimonials";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ProductContext } from "../../../contexts/ProductContext";

const B2EHomePage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState("");
  const { searchTerm } = useContext(ProductContext);
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("companyToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setCompany(decodedToken.id);
      if (decodedToken.role !== "company") {
        navigate("/b2e-login");
      }
    } else {
      navigate("/b2e-login");
    }
  }, []);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        setBannerData(response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/api/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredProducts = response.data.filter((product) =>{
          return product.priceByCompany?.some((entry) => entry.companyId === company);
        }
        );
        setProducts(filteredProducts);
        setFilteredProducts(filteredProducts)
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [baseUrl, token, company]);

  useEffect(() => {
    if (searchTerm) {
      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [searchTerm, products]);

  return (
    <>
      <Baner label="Welcome to prima gifting" bannerData={bannerData} />
      <LatestArrivalsB2E color="blue" products={filteredProducts} />
      <ProductCategories categories={categories} />
      <Testimonials color="blue" />
      <GetInTouch color="blue" />
    </>
  );
};

export default B2EHomePage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AdminAddProduct.css";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AdminAddProduct = () => {
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    shortDis: "",
    category: "",
    brand: "",
    sku: "",
    standardPrice: "",
    stock: "",
    isLatest: false,
    priceByCompany: [],
    images: [],
    additionalInfo: "",
    productType: [],
    companyPrice: "",
  });

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      const updatedTypes = checked
        ? [...prevData.productType, value]
        : prevData.productType.filter((type) => type !== value);
      return { ...prevData, productType: updatedTypes };
    });
  };

  const handleIsLatest = (e) => {
    const { checked } = e.target;
    setFormData((prevData) => {
      return { ...prevData, isLatest: checked };
    });
  };

  const fetchCategories = async () => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/api/category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBrands = async () => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/api/brand`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBrands(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCompanies = async () => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/api/company`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCompanies(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImages = async () => {
    if (formData.images.length > 0) {
      try {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_BASE_URL;

        const featuredImageBase64 = await convertToBase64(formData.images[0]);
        const galleryImagesBase64 = await Promise.all(
          formData.images.slice(1).map((image) => convertToBase64(image))
        );

        const imageData = {
          featuredImage: featuredImageBase64,
          galleryImages: galleryImagesBase64,
        };

        const response = await axios.post(
          `${baseUrl}/api/product-image`,
          imageData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          return response.data._id;
        } else {
          return;
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    } else {
      console.error("No images selected for upload");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({
      ...formData,
      images: files,
    });

    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const imageIds = await uploadImages();
    const productData = {
      ...formData,
      images: imageIds,
    };

    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await axios.post(`${baseUrl}/api/product`, productData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      navigate("/products");
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanyPriceChange = (index, field, value) => {
    const updatedpriceByCompany = formData.priceByCompany.map((price, idx) =>
      idx === index ? { ...price, [field]: value } : price
    );
    setFormData({
      ...formData,
      priceByCompany: updatedpriceByCompany,
    });
  };

  const addCompanyPrice = () => {
    setFormData({
      ...formData,
      priceByCompany: [
        ...formData.priceByCompany,
        { companyId: "", price: "" },
      ],
    });
  };

  const handleQuillChange = (value) => {
    setFormData({
      ...formData,
      additionalInfo: value,
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchBrands();
    fetchCompanies();
  }, []);

  return (
    <div className="admin-add-product-container">
      <div className="admin-add-product-header">
        <p>Add Product</p>
        {/* <button type="submit">Save</button> */}
      </div>
      <div className="admin-add-product-summary">
        <div className="admin-add-product-summary-left">
          <label>Category Image</label>
          {imagePreviews.length === 0 ? (
            <input
              type="file"
              name="image"
              multiple
              onChange={handleImageChange}
            />
          ) : (
            <img src={imagePreviews[0]} alt="Product" />
          )}
          {imagePreviews.length > 0 && (
            <div className="admin-add-product-small">
              {imagePreviews.slice(1).map((preview, index) => (
                <img key={index} src={preview} alt="Product" />
              ))}
            </div>
          )}
        </div>
        <div className="admin-add-product-summary-right">
          <input
            type="text"
            name="name"
            placeholder="Product Name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <textarea
            name="shortDis"
            placeholder="Product shortDis"
            value={formData.shortDis}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="admin-add-product-selection">
        <select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
        >
          <option value="">Categories</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </select>
        <select
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
        >
          <option value="">Brands</option>
          {brands.map((brand) => (
            <option key={brand._id} value={brand._id}>
              {brand.name}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="SKU"
          name="sku"
          value={formData.sku}
          onChange={handleInputChange}
        />
        <label>SKU</label>
        <input
          type="Number"
          placeholder="Price"
          name="standardPrice"
          value={formData.standardPrice}
          onChange={handleInputChange}
        />
        <label>PRICE</label>
        <input
          type="Number"
          placeholder="Stock"
          name="stock"
          value={formData.stock}
          onChange={handleInputChange}
        />
        <label>STOCK</label>
      </div>
      <div
        className="admin-add-product-selection"
        style={{
          marginLeft: 20,
        }}
      >
        <input
          type="Number"
          placeholder="Company Price"
          name="companyPrice"
          value={formData.companyPrice}
          onChange={handleInputChange}
        />
        <label>Company Price</label>
      </div>
      <div className="admin-add-product-company-price">
        <div className="admin-add-product-types">
          <label>Select Product Types:</label>
          <label>
            <input
              type="checkbox"
              value="B2B"
              checked={formData.productType.includes("B2B")}
              onChange={handleCheckboxChange}
            />
            B2B
          </label>
          <label>
            <input
              type="checkbox"
              value="B2C"
              checked={formData.productType.includes("B2C")}
              onChange={handleCheckboxChange}
            />
            B2C
          </label>
          <label>
            <input
              type="checkbox"
              value="B2E"
              checked={formData.productType.includes("B2E")}
              onChange={handleCheckboxChange}
            />
            B2E
          </label>
          <label>
            <input
              type="checkbox"
              value="isLatest"
              checked={formData.isLatest}
              onChange={handleIsLatest}
            />
            Latest Arrivals
          </label>
        </div>

        <div className="admin-add-product-company-price-upper">
          <button type="button" onClick={addCompanyPrice}>
            Add Company +
          </button>
        </div>
        {formData.priceByCompany.map((companyPrice, index) => (
          <div key={index} className="admin-add-product-company-price-lower">
            <select
              value={companyPrice.companyId}
              onChange={(e) =>
                handleCompanyPriceChange(index, "companyId", e.target.value)
              }
            >
              <option value="">Select Company</option>
              {companies.map((company) => (
                <option key={company._id} value={company._id}>
                  {company.name}
                </option>
              ))}
            </select>
            <input
              type="number"
              value={companyPrice.price}
              onChange={(e) =>
                handleCompanyPriceChange(index, "price", e.target.value)
              }
              placeholder="Company Price"
            />
          </div>
        ))}
      </div>
      <div className="admin-add-product-discription">
        <ReactQuill
          value={formData.additionalInfo}
          onChange={handleQuillChange}
          placeholder="Description..."
          className="admin-add-product-discription-quil"
        />
      </div>
      <div className="admin-add-product-buttons">
        <button onClick={handleFormSubmit} className="save">
          Save
        </button>
        <button className="delete">Delete</button>
      </div>
    </div>
  );
};

export default AdminAddProduct;
